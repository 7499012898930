<template>
  <div class="custom">
    <div class="sticky">
      <NavBar title="认证审核" left-arrow @click-left="onClickLeft" />
    </div>
    <div class="content">
      <Collapse v-model="basicActiveNames">
        <CollapseItem :title="projectCode" name="1" :is-link="false" disabled>
          <div class="item">
            <span class="label">清收方式：</span>
            <span class="value">{{
              enumToTxt(resOutsourceCaseDTO.way, "way")
            }}</span>
          </div>
          <div class="item">
            <span class="label">清收模式：</span>
            <span class="value">{{
              enumToTxt(resOutsourceCaseDTO.mode, "mode")
            }}</span>
          </div>
          <div class="item">
            <span class="label">审核类型：</span>
            <span class="value">{{
              enumToTxt(resCaseOperateLog.operateType, "operate")
            }}</span>
          </div>
          <div class="item">
            <span class="label">追回资金：</span>
            <span class="value">{{
              resCaseOperateLog.regainAmount | amount | dash
            }}</span>
          </div>
          <div class="item">
            <span class="label">机构名称：</span>
            <span class="value">{{
              resOutsourceCaseOrganizationDTO.orgName | dash
            }}</span>
          </div>
          <div class="item">
            <span class="label">是否申请组合清收：</span>
            <span class="value">{{
              resOutsourceCaseOrganizationDTO.applyMultiWay == "NO"
                ? "否"
                : "是"
            }}</span>
          </div>
          <div class="item">
            <span class="label">申请认证时间：</span>
            <span class="value">{{
              resOutsourceCaseDTO.createTime | dash
            }}</span>
          </div>
          <div class="item">
            <span class="label">申请理由：</span>
            <span class="value">{{ resCaseOperateLog.reason | dash }}</span>
          </div>
          <div class="item swipe-box">
            <span class="label">影像资料：</span>
            <div class="value">
              <Swipe @change="onChange">
                <SwipeItem v-for="(item, index) in fileMapIMG" :key="index">
                  <img :src="item.fileUrl" alt="" @click="viewImage(index)" />
                </SwipeItem>
                <template #indicator>
                  <div class="custom-indicator">
                    {{ current + 1 }}/{{ fileMapIMG.length }}
                  </div>
                </template>
              </Swipe>
            </div>
          </div>
        </CollapseItem>
        <CollapseItem
          title="认证审核"
          name="2"
          icon="/images/icon-case-audit.png"
          :is-link="false"
          disabled
        >
          <Form ref="form" validate-first>
            <Field
              readonly
              clickable
              required
              :value="form.approveEnumStr"
              clearable
              label="审核"
              placeholder="请选择"
              :rules="[{ required: true, timeValue }]"
              input-align="right"
              @click="showWayPicker = true"
            >
            </Field>
            <Field
              class="wth"
              label="生成委托函"
              :required="isPdf && form.approveEnum == 'PASS' ? true : false"
              v-model="form.pdf"
              :rules="[
                {
                  required: isPdf && form.approveEnum == 'PASS' ? true : false,
                  timeValue,
                  message: '请生成委托函',
                },
              ]"
              input-align="right"
            >
              <template #input>
                <Button
                  v-if="isPdf"
                  size="small"
                  @click="buildHorization"
                  type="primary"
                  plain
                  >生成委托函</Button
                >
                <Button
                  v-if="isPdf && form.pdf"
                  size="small"
                  @click="check"
                  type="primary"
                  plain
                >
                  <Icon name="browsing-history-o" />查看pdf
                </Button>
              </template>
            </Field>
            <Field
              class="wth"
              label="上传委托函"
              v-model="form.pdf"
              input-align="right"
            >
              <template #input>
                <Uploader
                  v-model="form.files"
                  :before-read="upload"
                  :before-delete="deleteFile"
                />
              </template>
            </Field>
            <Field
              required
              type="textarea"
              row="2"
              maxlength="200"
              show-word-limit
              v-model="form.approvalReason"
              label="审核结果"
              placeholder="请输入"
              :rules="[{ required: true, timeValue }]"
              input-align="right"
            ></Field>
          </Form>
        </CollapseItem>
      </Collapse>
    </div>
    <div class="bottom-btn">
      <div class="btns">
        <Button type="primary" hairline @click="save">确认</Button>
      </div>
    </div>
    <PdfViewer ref="PdfViewer" :pdfUrl="this.form.pdf"></PdfViewer>
    <Popup v-model="showWayPicker" position="bottom">
      <Picker
        title="审核结果"
        show-toolbar
        value-key="displayName"
        :columns="[
          { displayName: '通过', value: 'PASS' },
          { displayName: '拒绝', value: 'REJECT' },
        ]"
        @confirm="wayConfirm"
        @cancel="showWayPicker = false"
      ></Picker>
    </Popup>
  </div>
</template>

<script>
import {
  NavBar,
  Collapse,
  CollapseItem,
  Button,
  Form,
  Field,
  Popup,
  Swipe,
  SwipeItem,
  Picker,
  Uploader,
  Toast,
  ImagePreview,
  Icon,
} from "vant";
import PdfViewer from "@/components/PdfViewer.vue";
import Api from "@/api/index.js";
import { mapState } from "vuex";
export default {
  name: "Trial",
  components: {
    NavBar,
    Collapse,
    CollapseItem,
    Button,
    Form,
    Field,
    Popup,
    Swipe,
    SwipeItem,
    Picker,
    Uploader,
    PdfViewer,
    Icon,
  },
  data() {
    return {
      activeName: "basic",
      basicActiveNames: ["1", "2", "3"],
      timeValue: /^\s*$/,
      showWayPicker: false,
      firstname: [],
      wayEnum: [],
      modeEnum: [],
      operateEnum: [],
      outsourceCase: {},
      resOutsourceCaseDTO: {},
      resCaseOperateLog: {},
      resOutsourceCaseOrganizationDTO: {},
      currentItem: {},
      fileMapIMG: [],
      form: {
        files: [],
      },
      current: 0,
    };
  },
  created() {
    this.projectCode = this.$route.query.projectCode;
    this.init();
  },
  computed: {
    ...mapState(["info"]),
    isPdf() {
      return (
        ["CAR", "MULTI_WAY"].includes(this.resOutsourceCaseDTO.way) ||
        this.resOutsourceCaseOrganizationDTO.applyMultiWay == "YES"
      );
    },
  },
  methods: {
    init() {
      Api.getOutsourceCase(this.projectCode).then(({ data }) => {
        this.outsourceCase = data || {};
        this.getApproveInfo();
      });
      this.loadWayEnum();
      this.loadModeEnum();
      this.loadOperateEnum();
    },
    getApproveInfo() {
      this.currentItem =
        JSON.parse(decodeURIComponent(this.$route.query.item)) || {};
      Api.getApproveInfo(this.currentItem).then((res) => {
        let data = res.data || {};
        this.resOutsourceCaseDTO = data.resOutsourceCaseDTO || {};
        this.resCaseOperateLog = data.resCaseOperateLog || {};
        this.resOutsourceCaseOrganizationDTO =
          data.resOutsourceCaseOrganizationDTO || {};
        this.fileMapIMG = data?.fileMap?.IMG || [];
        this.form.logNo = this.resCaseOperateLog.logNo;
        // if (Array.isArray(res.data.fileMap.IMG)) {
        //   res.data.fileMap?.IMG.forEach((item) => {
        //     this.form.files.push({
        //       ...item,
        //       url: item.fileUrl,
        //     });
        //   });
        // }
      });
    },
    // 枚举转文本
    enumToTxt(key, type) {
      let obj = {};
      if (type === "way") {
        this.wayEnum.forEach((item) => {
          obj[item.value] = item.displayName;
        });
      }

      if (type == "operate") {
        this.operateEnum.forEach((item) => {
          obj[item.value] = item.displayName;
        });
      }

      if (type == "mode") {
        this.modeEnum.forEach((item) => {
          obj[item.value] = item.displayName;
        });
      }

      return obj[key];
    },

    //获取清收方式
    async loadWayEnum() {
      const _error = "清收方式枚举加载失败！";
      try {
        const { success, data, message } = await Api.getRecoverWayEnum();
        if (!success) {
          Toast(message || _error);
        }
        this.wayEnum = data || [];
      } catch (error) {
        Toast(error || _error);
      }
    },

    //获得清收模式
    async loadModeEnum() {
      const _error = "清收模式枚举加载失败！";
      try {
        const { success, data, message } = await Api.getRecoverModeEnum();
        if (!success) {
          Toast(message || _error);
        }
        this.modeEnum = data || [];
      } catch (error) {
        Toast(error || _error);
      }
    },

    async loadOperateEnum() {
      const _error = "操作类型枚举加载失败！";
      try {
        const { success, data, message } = await Api.getCaseOperateTypeEnum();
        if (!success) {
          Toast(message || _error);
        }
        this.operateEnum = data || [];
      } catch (error) {
        Toast(error || _error);
      }
    },
    onChange(index) {
      this.current = index;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    async save() {
      await this.$refs.form.validate();
      const toast = Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      try {
        this;
        let data = {
          ...this.form,
          caseNo: this.currentItem.caseNo,
          files: this.form.files.map((i) => i.url),
          mode: this.currentItem.mode,
          operator: this.info.userId,
          operatorName: this.info.username,
          refNo: this.currentItem.refNo,
        };
        let { success, message } = await Api.approve(data);
        if (!success) {
          Toast(message || "出错了");
          return;
        }
        Toast("审核成功");
        setTimeout(() => {
          this.onClickLeft();
        }, 1000);
        toast.clear();
      } catch (error) {
        toast.clear();
      }
    },
    wayConfirm({ value, displayName }) {
      this.form.approveEnum = value;
      this.$set(this.form, "approveEnumStr", displayName);
      this.showWayPicker = false;
    },
    // 删除
    deleteFile(file, o) {
      this.form.files.splice(o.index, 1);
      return file;
    },

    //生成委托函
    buildHorization() {
      let paramsList = [
        {
          caseNo: this.resOutsourceCaseDTO.caseNo,
          orgNo: this.resOutsourceCaseOrganizationDTO.orgNo,
          mode: this.resOutsourceCaseDTO.mode,
          way: this.resOutsourceCaseDTO.way,
        },
      ];
      const toast = Toast.loading({
        message: "委托函生成中...",
        duration: 0,
        forbidClick: true,
      });
      this.horizationLoading = true;
      Api.generateAuthorization(paramsList)
        .then(({ data, success, message }) => {
          this.horizationLoading = false;
          toast.clear();
          if (!success) {
            Toast(message || "出错了");
            return;
          }
          if (Array.isArray(data) && data.length > 0) {
            if (data[0].isAuth == "NO" || data[0].result == "FAIL") {
              Toast(data[0].failReason || "出错了");
              return;
            }
            this.isBuildHorization = true;
            this.$set(this.form, "pdf", data[0].url);
          }
        })
        .catch(() => {
          toast.clear();
          this.horizationLoading = false;
        });
    },

    async upload(file) {
      const params = new FormData();
      params.append("file", file);
      Toast.loading({
        message: "上传中..",
        forbidClick: true,
        duration: 0,
      });
      const res = await Api.uploadFile(params);
      Toast.clear();
      if (res.success) {
        if (!this.form.files) {
          this.form.files = [];
        }
        this.form.files.push({ url: res.data.ossAccessUrl });
        return Promise.reject();
      }
    },

    check() {
      this.$refs.PdfViewer.show();
    },

    viewImage(index) {
      ImagePreview({
        images: this.fileMapIMG.map((i) => i.fileUrl),
        startPosition: index,
        closeable: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.wth {
  /deep/.van-field__label {
    width: 90px;
  }
  /deep/.van-field__body {
    .van-field__control {
      flex-direction: column;
      align-items: flex-end;
      .van-button {
        margin-bottom: 15px;
      }
    }
  }
}
/deep/ .van-nav-bar__content {
  .van-icon,
  .van-nav-bar__title {
    color: #fff;
  }
}
.sticky {
  padding-bottom: 50px;
  background: linear-gradient(
    180deg,
    #59c47e 0%,
    rgba(89, 196, 126, 0.6) 44%,
    rgba(245, 247, 249, 0.75) 100%
  );
}
.content {
  padding: 12px;
  font-size: 14px;
  margin-top: -50px;
}
.van-nav-bar {
  background: none;
}
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      color: #666;
      &.large {
        flex: 0 0 100px;
      }
    }
    .value {
      flex: 1;
      color: #333;
      .van-swipe {
        height: 150px;
        color: #fff;
        .van-swipe-item {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &.swipe-box {
      flex-direction: column;
      .value {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
</style>
